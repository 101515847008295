<template>
    <div class="main-nav-container" :style="mainNavVars">
        <ul class="main-nav-content">
            <li class="nav-content-item" @click="updateNavSelection()">
                <router-link
                    to="/"
                    class="main-nav-link"
                    :class="{ active: homeActive }"
                >
                    Home
                </router-link>
            </li>
            <li class="nav-content-item" @click="updateNavSelection()">
                <router-link
                    to="/research-and-work-experience"
                    class="main-nav-link"
                    :class="{ active: workExperienceActive }"
                >
                    Research / Work Experience
                </router-link>
            </li>
            <li class="nav-content-item" @click="updateNavSelection()">
                <router-link
                    to="/projects"
                    class="main-nav-link"
                    :class="{ active: projectsActive }"
                >
                    Projects
                </router-link>
            </li>
            <li class="nav-content-item" @click="updateNavSelection()">
                <router-link
                    to="/courses"
                    class="main-nav-link"
                    :class="{ active: coursesActive }"
                >
                    Courses
                </router-link>
            </li>
        </ul>
    </div>
</template>

<script type="text/javascript">
export default {
    data() {
        return {
            navColor: "transparent",
            homeActive: false,
            workExperienceActive: false,
            projectsActive: false,
            coursesActive: false,
        };
    },

    mounted() {
        this.updateColor();
        this.updateNavSelection();
        window.addEventListener('resize', this.updateNavSelection);
    },

    computed: {
        mainNavVars() {
            return {
                "--nav-color": this.navColor,
            };
        },
    },

    methods: {
        updateColor() {
            if (this.$route.name == "Home") {
                this.navColor = "transparent";
            } else {
                this.navColor = "#f03a3a";
            }
        },
        updateNavSelection() {
            if (this.$route.name == "Home") {
                this.homeActive = true;
                this.workExperienceActive = false;
                this.projectsActive = false;
                this.coursesActive = false;
            } else if (this.$route.name == "Research and Work Experience") {
                this.homeActive = false;
                this.workExperienceActive = true;
                this.projectsActive = false;
                this.coursesActive = false;
            } else if (this.$route.name == "Projects") {
                this.homeActive = false;
                this.workExperienceActive = false;
                this.projectsActive = true;
                this.coursesActive = false;
            } else if (this.$route.name == "Courses"){
                this.homeActive = false;
                this.workExperienceActive = false;
                this.projectsActive = false;
                this.coursesActive = true;
            }
        },
    },

    beforeUnmount() {
        window.removeEventListener('resize', this.updateNavSelection);
    },

    watch: {
        $route() {
            this.updateColor();
            this.updateNavSelection();
        },
    },
};
</script>
<style lang="scss">
.main-nav-container {
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    width: 100%;
    height: var(--nav-height);
    background-color: var(--nav-color);
    transition: background-color 0.25s ease-in-out;
    z-index: 999;
    clip-path: polygon(0 0, 100% 0, 100% 90%, 90% 100%, 10% 100%, 0 90%);
}
.main-nav-content {
    list-style: none;
    width: 100%;
    height: var(--nav-height);
    justify-content: space-around;
    align-items: center;
    z-index: 999;
    display: flex;
    flex-direction: row;
    margin-top: 0;
}
.nav-content-item {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    display: flex;
    cursor: pointer;
}
.main-nav-link {
    position: relative;
    text-decoration: none;
    color: #fff;
    z-index: 1000;
    font-size: 20px;
    text-transform: uppercase;
    &::after {
        content: "";
        position: absolute;
        bottom: -2px;
        width: 0;
        height: 2px;
        background-color: #fff;
        left: 0;
        transform-origin: center;
        transition: width 0.15s ease-in-out;
    }
}
.main-nav-link:hover::after {
    width: 100%;
}
.active::after {
    width: 100%;
}
</style>
